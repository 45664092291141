export const routes = [
    {
        path: '/inventories',
        name: 'inventories.browse',
        component: () => import(/* webpackChunkName: "InventoriesBrowse" */ '@/views/app/InventoriesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/inventories/:id/clone',
        name: 'inventories.clone',
        component: () => import(/* webpackChunkName: "InventoriesActions" */ '@/views/app/InventoriesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/inventories/create',
        name: 'inventories.create',
        component: () => import(/* webpackChunkName: "InventoriesActions" */ '@/views/app/InventoriesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/inventories/:id/edit',
        name: 'inventories.edit',
        component: () => import(/* webpackChunkName: "InventoriesActions" */ '@/views/app/InventoriesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/inventories/:id/delete',
        name: 'inventories.delete',
        component: () => import(/* webpackChunkName: "InventoriesActions" */ '@/views/app/InventoriesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/inventories/:id/show',
        name: 'inventories.show',
        component: () => import(/* webpackChunkName: "InventoriesActions" */ '@/views/app/InventoriesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
]